<template>
    <div :class="CSSClasses">
        <div class="content-wrap">
            <div class="info-lockup">
                <Avatar class="avatar" v-if="person?.avatar && size !== '100'" v-bind="avatarProps" />
                <div class="person-titles">
                    <Anchor v-if="showInternalProfile" :url="person?.path">
                        <Typography class="title" as="p" :variant="titleVariant">
                            {{ personName }}
                        </Typography>
                    </Anchor>
                    <Typography v-else class="title" as="p" :variant="titleVariant">
                        {{ personName }}
                    </Typography>
                    <Typography v-if="person?.personTitle" class="person-title" as="p" :variant="personTitleVariant">
                        {{ personTitleOverride || person?.personTitle }}
                    </Typography>
                </div>
            </div>
            <div v-if="showDetails && !hideDescription" class="person-details">
                <RichTextElements
                    v-if="isDekNotEmpty(richText) && size !== '25'"
                    :elements="richText"
                    bodyStyle="serif-large"
                    class="dek"
                />
                <RichTextElements
                    v-else-if="isDekNotEmpty(richText) && !person?.personTitle"
                    :elements="person?.dek"
                    bodyStyle="body-display-micro-italic"
                />
                <div
                    v-if="
                        (!isPersonExternal && person?.socialNetworks) || (showInternalProfile && person?.socialNetworks)
                    "
                    class="socials"
                >
                    <Button
                        v-for="social in person?.socialNetworks"
                        :key="social.id"
                        :url="getSocialUrl(social.handle, social.network)"
                        newTab
                        iconPosition="left"
                        variant="secondary"
                        class="social-link"
                        :theme="themeColor"
                    >
                        {{ social.handle }}
                        <template #icon>
                            <Icon :name="social.network" />
                        </template>
                    </Button>
                </div>

                <div v-if="!showInternalProfile && hasExternalLink" class="socials">
                    <Button
                        :url="person?.externalLink?.url"
                        newTab
                        variant="secondary"
                        class="social-link"
                        :theme="themeColor"
                    >
                        {{ person?.externalLink?.label }}
                    </Button>
                </div>
            </div>
        </div>
        <Avatar class="avatar" v-if="person?.avatar && size === '100'" v-bind="avatarProps" />
    </div>
</template>

<script setup>
const props = defineProps({
    size: {
        ...sizes,
    },
    person: {
        type: Object,
    },
    personTitleOverride: {
        type: String,
    },
    descriptionOverride: {
        type: Object,
    },
    hideDescription: {
        type: Boolean,
    },
});

const themeColor = inject('tierTheme', 'light');

const CSSClasses = computed(() => [
    'person-block',
    `size-${props.size}`,
    `theme-${themeColor}`,
    props.person?.personDetails.personType.includes('carnegieExpert') ? 'expert' : null,
    props.person?.avatar ? 'has-avatar' : null,
]);

const personName = getPersonFullName(props.person);

const titleVariant = computed(() => {
    if (props.size === '25') {
        return 'body-display-small';
    }
    if (props.size === '33') {
        return 'body-display-large';
    }
    if (props.size === '50') {
        return 'h5-display';
    }
    if (props.size === '100') {
        return 'h3-display';
    }
});

const personTitleVariant = computed(() => {
    if (props.size === '25') {
        return 'body-display-micro';
    }
    return 'body-display-small-italic';
});

const avatarProps = computed(() => ({
    path: props.person?.path,
    image: { ...props.person?.avatar },
    size: props.size === '100' ? 'large' : props.size === '33' ? 'medium' : 'default',
}));

const isPersonExternal = computed(() => props.person?.type === 'external');
const hasExternalLink = computed(() => props.person?.externalLink?.url && props.person?.externalLink?.label);

const showInternalProfile = computed(() => {
    if (props.person?.type === 'internal') {
        return true;
    } else {
        return props.person?.hasProfile;
    }
});

const richText = isDekNotEmpty(props.descriptionOverride) ? props.descriptionOverride : props.person?.dek;

const showDetails = !!(isDekNotEmpty(props.descriptionOverride) || isDekNotEmpty(props.person?.dek));
</script>

<style lang="scss" scoped>
.person-block {
    background-color: color(blanc);
}

.content-wrap {
    padding: 1.6rem 2.4rem;
}

.title {
    color: palette(text);
}

.expert {
    .title {
        color: palette(text-blue);
    }
}
.person-titles {
    a .title {
        color: palette(text-blue);
    }
}

.avatar {
    .info-lockup & {
        flex-shrink: 0;
    }
}
.theme-dark {
    &.size-33 .content-wrap,
    &.size-50 .content-wrap {
        background-color: color(noir, default, 0.2);
        height: 100%;
    }
    .title,
    .dek {
        color: color(blanc, default, 0.8);
    }

    .person-title {
        color: color(blanc, default, 0.6);
    }
    .person-titles a .title {
        color: color(blanc, default, 0.8);
    }
}

.size-25 {
    &.person-block {
        background-color: transparent;
    }

    .info-lockup {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .avatar {
        margin-bottom: 1.6rem;
    }

    .socials {
        margin-top: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.8rem;
    }

    .title,
    .person-title {
        text-align: center;
        max-width: 24rem;
    }

    .person-details {
        display: flex;
        justify-content: center;
    }
}

.size-33,
.size-50 {
    &.person-block {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0.4rem;
            background-color: palette(border);
            background-color: color(teal);
        }
    }

    outline: 1px solid palette(border-light);

    .size-33 &,
    .size-50 &,
    .size-100 & {
        outline: none;
    }

    .person-details {
        border-top: 1px solid palette(border-light);
    }

    .avatar {
        margin-inline-start: 1.6rem;
    }
    .info-lockup {
        display: flex;
        min-height: 8rem;
        justify-content: space-between;
        align-items: center;
    }

    .socials {
        margin-top: 2.4rem;
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
    }

    &.has-avatar {
        .info-lockup {
            flex-direction: row-reverse;
        }
    }

    &.theme-dark.person-block {
        background-color: palette(bg-dark);
    }
}

.size-33 {
    .dek {
        margin-top: 1.6rem;
    }

    .person-details {
        margin-top: 1rem;
        padding-bottom: 1.6rem;
    }
}

.size-50 {
    .dek {
        margin-top: 3.6rem;
    }

    .person-details {
        margin-top: 1.6rem;
        padding-bottom: 1rem;
    }
}

.size-100 {
    &.person-block {
        background-color: transparent;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    > .content-wrap {
        max-width: 60rem;
        flex-basis: 75%;
    }

    .avatar {
        margin-inline-start: 1.6rem;
    }

    .socials {
        margin-top: 3.2rem;
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
    }
}
</style>
